import React from "react"
import "./HeaderTop.scss"
import useWindowSize from "../../hook/useWindowSize"
import useScrollPosition from "../../hook/useScrollPosition"
import { Link } from "gatsby"
import cx from "classnames"

import LangSwitcher from "../langSwitcher"
// import ThemeToggler from "../ThemeToggler"
import Menu from "../Menu/Menu"
import PhoneMenu from "../PhoneMenu/PhoneMenu"
import Logo from "../UI/Logo"
import { Stack } from "@mui/material"
import useDomainName from "../../hook/useDomainName"

function index({ navigatorMenu }) {
  const screen = useWindowSize()
  const scrollPosition = useScrollPosition()
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  const { isUSASite, phone, formattedPhone } = useDomainName()

  return (
    <div
      className={cx(
        "header__top",
        scrollPosition > 100 && pathname === "/" ? "header__top_fixed" : "",
        pathname !== "/" ? "header__top_fixed" : "",
      )}
    >
      <Link className="logo" to="/">
        <Logo size={50} showCompanyName lightBackground />
      </Link>

      <Menu
        style={{
          display: screen.width >= 991 && navigatorMenu ? "flex" : "none",
        }}
      />

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: 4,
          position: "absolute",
          right: "16px",
        }}
      >
        {screen.width >= 991 || !navigatorMenu ? (
          <>
            {isUSASite ? (
              <>
                {/*TODO: вынести второй телефон в конфиг*/}
                <a
                  data-telegram="usava_team"
                  href="tel:+18475046041"
                  className="header__phone"
                >
                  +1 847 504 6041
                </a>
              </>
            ) : (
              <a
                data-telegram="sava_team"
                href={`tel:${phone}`}
                className="header__phone"
              >
                {formattedPhone}
              </a>
            )}

            <LangSwitcher />
            {/* <ThemeToggler /> */}
          </>
        ) : (
          ""
        )}
      </Stack>
      <div className={screen.width > 991 ? "hidden" : ""} />
      <PhoneMenu className={!navigatorMenu ? "hidden" : ""} />

      {/*{(window.location.pathname === '/magento' && !this.state.isPortativeDevice) ? (*/}
      {/*  <>*/}
      {/*    <Link className="header__special" to="/moving-from-magento-1-to-magento-2-upgrade-service">*/}
      {/*      Magento 2.3.x*/}
      {/*    </Link>*/}
      {/*  </>*/}
      {/*) : ''}*/}
    </div>
  )
}

export default index
